$bgc-primary: #FFA800;
$bgc-secondary: #603f23;
$bgc-black: #0b0b0b;
$bgc-light: #E3E3E3;

body, #root, .App {
    height: 100%;
    padding: 0;
  }

#portfolio{
    margin: 15px auto;
    border: 3px solid black;
}

// ! Overrides
.form-check-input:focus,
.form-control:focus,
.form-select:focus {
    border-color: $bgc-secondary !important;
    box-shadow: 0 0 0 0.25rem rgba($bgc-secondary, 0.25) !important;
}
.form-check-input:checked {
    background-color: $bgc-secondary !important;
    border-color: $bgc-secondary !important;
}
.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23603f23%27/%3e%3c/svg%3e") !important;
}
.form-switch .form-check-input:checked {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

$theme-colors: ("primary": #FFA800, "danger": #ff4136);
@import '~bootstrap/scss/bootstrap';
@import './fonts/Fonts.scss';

.bgc-light {
    background-color: $bgc-light;
}

.bgc-primary {
    background-color: $bgc-primary;
}

.bgc-black {
    background-color: $bgc-black;
}

.text-c-light {
    color: $bgc-light;
}

.text-c-muted {
    color: #ababab;
}

.text-c-dark {
    color: #666666;
}

.text-c-primary {
    color: $bgc-primary;
}

.btnc-cta {
    border: solid 2px $bgc-primary;
}

// TEMPLATE TEXT
.template-text {}

// TEMPLATE FROM
.template-form {
    .row {
        max-width: 1000px;
    }

    .submit-success {
        background-color: $bgc-light;
        border: unset;
        color: #343434;
        border-radius: 0;
        margin: 0;
    }
}

// CONTENT HEADLINE
.content-headline {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 0.9 !important;
    }

    .animation {
        width: 15vw;
        height: 4px;
        background-color: $bgc-primary;
        position: relative;
        animation: hr 4s infinite;
    }
    @keyframes hr {

        0%,
        100% {
            left: -1%;
        }

        50% {
            left: 1%;
        }
    }
    @include media-breakpoint-up(sm) {
        .animation {
            width: 10vw;
        }
    }

    &.bgc-primary {
        .animation {
            background-color: $bgc-black;
        }
    }

}

// TEMPLATE FOOTER
.template-footer {
    img {
        max-width: 200px;
        filter: brightness(0.85);
    }
}

// TEMPLATE OPENER
.template-opener {
    .bottom-box.mobile {
        display: none;
        position: relative;
        z-index: 1;
        width: 100%;
    }

    .bottom-box.desktop {
        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }

    .bottom-box.mobile {
        @include media-breakpoint-down(md) {
            display: block !important;
        }
    }

    .bottom-box {
        background-color: $bgc-light;
    }

    .seperator {
        width: 45%;
        height: 4px;
        background-color: $bgc-primary;
    }

    .img-hero {
        right: 0;
        max-width: unset;
        width: 100%;
        z-index: -1;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
    }

    .left {
        z-index: 1;
    }

    .right {
        z-index: 0;
    }

    h1 {
        font-size: 40px;
        line-height: 30px;
        font-weight: 600;
        margin: 70px 0 0;
        @include media-breakpoint-up(sm) {
            font-size: 60px;
            line-height: 50px;
        }
        @include media-breakpoint-up(md) {
            font-size: 80px;
            line-height: 60px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 100px;
            line-height: 80px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 110px;
            line-height: 90px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 115px;
            line-height: 95px;
        }
    }

    .overlapper {
        width: 85%;
        right: 0;
        top: 0;
        max-width: 750px;
        overflow: hidden;
        background-color: #f4cd824f;
    }

    .content-holder {
        display: flex;
        align-items: flex-start;
        position: relative;
        flex-direction: column;
        justify-content: center;
        z-index: 1;

        h1 {
            width: 65%;
        }

        .bottom-box {
            width: 39%;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

}

// TEMPALTE HEADER 
.template-header {

    .logo {
        width: 100px;
        @include media-breakpoint-up(sm) {
            width: 120px;
        }
        @include media-breakpoint-up(md) {
            width: 140px;
        }
        @include media-breakpoint-up(lg) {
            width: 160px;
        }
        @include media-breakpoint-up(xl) {
            width: 180px;
        }
        @include media-breakpoint-up(xxl) {
            width: 200px;
        }
    }
}

.menu-overlay {
    position: fixed;
    top: 0;
    z-index: 1;

    .menu-item {
        .menu-item-content {
          transition: all .4s;
            h2,
            p {
                float: left;
            }
            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }
    }

    .container {
        max-width: 800px;

        p {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

}

.burger {
    display: flex;
    outline: none;
    height: 30px;
    width: 50px;
    border: 0;
    padding: 20px 0 0;
    background: transparent;
    transition: all 250ms ease-out;
    cursor: pointer;
}
.burger:after,
.burger:before {
    content: '';
    height: 3px;
    position: absolute;
    background: #1a1a1a;
    transition: all 250ms ease-out;
    will-change: transform;
    margin-top: 8px;
}
.burger:before {
    width: 5%;
    max-width: 55px;
}
.burger:after {
    width: 3.5%;
    max-width: 40px;
}
.burger.active:after,
.burger.active:before {
    width: 3%;
    max-width: 45px;
}
.burger:before {
    transform: translateY(-3px);
}
.burger:after {
    transform: translateY(3px);
}
.burger.active:before {
    background: #424242 !important;
}
.burger.active:after {
    background: #424242 !important;
}
.burger.menu:before {
    background: $bgc-light;
}
.burger.menu:after {
    background: $bgc-light;
}

// line transition
.active.burger:before {
    transform: translateY(0) rotate(45deg);
}
.active.burger:after {
    transform: translateY(0) rotate(-45deg);
}
